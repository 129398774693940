"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _YHHeader = _interopRequireDefault(require("./YHHeader"));

var _divider = _interopRequireDefault(require("./divider"));

var _YHBottom = _interopRequireDefault(require("./YHBottom"));

var _YHFooter = _interopRequireDefault(require("./YHFooter"));

var _YHCard = _interopRequireDefault(require("./YHCard"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  components: {
    YHHeader: _YHHeader.default,
    Divider: _divider.default,
    YHBottom: _YHBottom.default,
    YHFooter: _YHFooter.default,
    YHCard: _YHCard.default
  },
  props: {},
  data: function data() {
    return {
      text: "京杰科技旗下的“有活”平台基于SAAS模式，通过政策引擎和数据算法提供“合规、安全、便捷、高效”的一站式企业人力资源优化解决方案，对接各地区本地化合规服务伙伴，形成全国业务交付网络同时为各地设计行业、金融行业以及高端制造业的自由职业者创业项目提供技术支持与运营保障。",
      cards: [{
        title: "技术精湛",
        content: "技术团队拥有丰富的经验，开发理念创新、先进。",
        bg: require("./images/index/jishujingzhan.png")
      }, {
        title: "方案严谨",
        content: "拥有资深专家团队设计的方案， 保证方案更加的合规化。",
        bg: require("./images/index/fangan.png")
      }, {
        title: "资金实力雄厚",
        content: "母公司小桥金融资金实力雄厚 提供资金支撑。",
        bg: require("./images/index/zijin.png")
      }, {
        title: "系统高效",
        content: "全流程线上化完成，使得系统运行更加高效。",
        bg: require("./images/index/xitong.png")
      }, {
        title: "资金安全",
        content: "资金由银行监管，确保企业资金安全支付。",
        bg: require("./images/index/zijinanquan.png")
      }, {
        title: "安全保障",
        content: "数据加密，全力护航，从而保证数据隐私和安全。",
        bg: require("./images/index/anquan.png")
      }, {
        title: "全程服务",
        content: "售前、服务中全程系统都有相应工作人员跟踪响应。",
        bg: require("./images/index/fuwu.png")
      }, {
        title: "专业风控",
        content: "业务前端、发放风控，真实用工、发放审查。",
        bg: require("./images/index/fengkong.png")
      }]
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};
exports.default = _default;