"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _antDesignVue = require("ant-design-vue");

var _store = _interopRequireDefault(require("@/store"));

var _xqjrModuleAuth = require("xqjr-module-auth");

var _router = _interopRequireDefault(require("@/router"));

// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 60000 // request timeout

});

var params2Query = function params2Query(params) {
  if (!params) {
    return "";
  }

  var keys = Object.keys(params);
  var url = "?";

  for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
    var key = _keys[_i];

    if (!(params[key] === null || params[key] === undefined || params[key] === "")) {
      url += "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key]), "&");
    }
  }

  url = url.substring(0, url.length - 1);
  return url;
}; // request interceptor


service.interceptors.request.use(function (config) {
  config.headers.deviceType = _store.default.state.settings.deviceType || "web";
  config.headers["token"] = (0, _xqjrModuleAuth.getToken)();

  if (config.method === "post") {
    if (typeof config.data === "string" && config.data.indexOf("=") === -1) {
      config.data = JSON.stringify(config.data);
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    }
  } else if (config.method === "get") {
    config.url = config.url + params2Query(config.params);
    config.params = {};
  }

  return config;
}, function (error) {
  if (process.env.NODE_ENV === "development") {
    console.log(error);
  }

  return Promise.reject("请求失败");
}); // response interceptor

service.interceptors.response.use(function (response) {
  if (response.data) {
    if (response.data.code === 401) {
      _store.default.dispatch("auth/refreshToken").then(function () {
        localStorage.setItem("refreshStatus", "done");

        _antDesignVue.notification.warning({
          message: "提示",
          description: "请求提交失败，请重新提交"
        });
      }).catch(function () {
        _antDesignVue.notification.warning({
          message: "警告",
          description: "登录已失效，即将跳转到登录页面"
        });

        setTimeout(function () {
          _store.default.dispatch("auth/resetToken").then(function () {
            location.reload();
          });
        }, 1000);
      });
    } else if (response.data.code === 403) {
      switch (response.data.data) {
        case "check":
          _store.default.dispatch("auth/refreshToken").then(function () {
            localStorage.setItem("refreshStatus", "done");

            _antDesignVue.notification.warning({
              message: "提示",
              description: "请求提交失败，请重新提交"
            });
          }).catch(function () {
            localStorage.setItem("refreshStatus", "done");

            _antDesignVue.notification.warning({
              message: "警告",
              description: "登录已失效，即将跳转到登录页面"
            });

            setTimeout(function () {
              _store.default.dispatch("auth/resetToken").then(function () {
                location.reload();
              });
            }, 2000);
          });

          break;

        case "refresh":
          _antDesignVue.notification.warning({
            message: "警告",
            description: response.data.msg
          });

          setTimeout(function () {
            _store.default.dispatch("auth/resetToken").then(function () {
              location.reload();
            });
          }, 2000);
          break;

        default:
          _antDesignVue.notification.warning({
            message: "警告",
            description: response.data.msg
          });

          break;
      }
    } else if (response.data.code === 200) {
      if (response.data.success) {
        return response.data;
      } else {
        _antDesignVue.notification.error({
          message: "失败",
          description: response.data.msg || "请求失败"
        });

        return Promise.reject(new Error("请求失败"));
      }
    } else if (response.data.code === 400) {
      var reqStatus = localStorage.getItem("reqStatus");

      if (reqStatus === "400") {
        return Promise.reject(new Error(response.data.msg || "参数错误"));
      }

      localStorage.setItem("reqStatus", "400");
      var timer = setTimeout(function () {
        localStorage.removeItem("reqStatus");
        clearTimeout(timer);
      }, 2000);

      _antDesignVue.notification.error({
        message: "失败",
        description: response.data.success === false ? response.data.msg || "参数错误" : "参数错误"
      });

      return Promise.reject(new Error("参数错误"));
    } else {
      var _reqStatus = localStorage.getItem("reqStatus");

      if (_reqStatus === "500") {
        return Promise.reject(new Error(response.data.msg || "请求失败"));
      }

      localStorage.setItem("reqStatus", "500");

      var _timer = setTimeout(function () {
        localStorage.removeItem("reqStatus");
        clearTimeout(_timer);
      }, 2000);

      _antDesignVue.notification.error({
        message: "失败",
        description: response.data.success === false ? response.data.msg || "请求失败" : "请求失败"
      });

      return Promise.reject(new Error("请求失败"));
    }
  } else {
    return Promise.reject(new Error("请求失败"));
  }
}, function (error) {
  if (process.env.NODE_ENV === "development") {
    console.log(error);
  }

  if (error.response) {
    if (error.response.status === 401) {
      if (_router.default.currentRoute.path !== "/auth/login") {
        var reqStatus = localStorage.getItem("reqStatus");

        if (reqStatus === "401") {
          return Promise.reject(error);
        }

        localStorage.setItem("reqStatus", "401");
        var timer = setTimeout(function () {
          localStorage.removeItem("reqStatus");
          clearTimeout(timer);
        }, 2000);

        if (error.response.data.data === "kicked") {
          _antDesignVue.notification.warning({
            message: "警告",
            description: error.response.data.msg
          });

          setTimeout(function () {
            _store.default.dispatch("auth/resetToken").then(function () {
              location.reload();
            });
          }, 2000);
        } else {
          _store.default.dispatch("auth/refreshToken").then(function () {
            localStorage.setItem("refreshStatus", "done");

            _antDesignVue.notification.warning({
              message: "提示",
              description: "请求提交失败，请重新提交"
            });
          }).catch(function () {
            localStorage.setItem("refreshStatus", "done");

            _antDesignVue.notification.warning({
              message: "警告",
              description: "登录已失效，即将跳转到登录页面"
            });

            setTimeout(function () {
              _store.default.dispatch("auth/resetToken").then(function () {
                location.reload();
              });
            }, 2000);
          });
        }
      }
    } else if (error.response.status === 403) {
      _antDesignVue.notification.warning({
        message: "警告",
        description: "没有操作权限"
      });
    } else if (error.response.status === 502) {
      var _reqStatus2 = localStorage.getItem("reqStatus");

      if (_reqStatus2 === "502") {
        return Promise.reject(new Error(error.response.data.msg || "服务器发生错误"));
      }

      localStorage.setItem("reqStatus", "502");

      var _timer2 = setTimeout(function () {
        localStorage.removeItem("reqStatus");
        clearTimeout(_timer2);
      }, 2000);

      _antDesignVue.notification.error({
        message: "失败",
        description: "服务器发生错误"
      });
    } else {
      _antDesignVue.notification.error({
        message: "失败",
        description: error.message || "请求失败"
      });
    }
  } else if (error.request) {
    _antDesignVue.notification.error({
      message: "错误",
      description: error.message || "请求发送错误"
    });
  } else {
    _antDesignVue.notification.error({
      message: "错误",
      description: error.message
    });
  }

  return Promise.reject(error);
});
var _default = service;
exports.default = _default;