"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api");

var _xqjrPluginCommon = require("xqjr-plugin-common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadSiteFile: _xqjrPluginCommon.UploadSiteFile
  },
  data: function data() {
    return {
      title: "公司",
      info: {},
      statusList: [{
        value: 1,
        label: "已发包"
      }, {
        value: 2,
        label: "已审核通过"
      }, {
        value: 3,
        label: "已拒绝"
      }, {
        value: 4,
        label: "已分包"
      }, {
        value: 5,
        label: "已完成"
      }, {
        value: 6,
        label: "已取消"
      }]
    };
  },
  created: function created() {
    // 获取参数
    var id = this.$route.query.id;
    this.title = this.$route.query.title;
    this.getData(id);
  },
  methods: {
    goBack: function goBack() {
      this.$router.go(-1);
    },
    getData: function getData(id) {
      var _this = this;

      (0, _api.detailReq)(id).then(function (res) {
        _this.info = res.data;
      });
    }
  }
};
exports.default = _default;