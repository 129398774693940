"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api");

var _axios = _interopRequireDefault(require("axios"));

var _xqjrModuleAuth = require("xqjr-module-auth");

var _tool = require("@/utils/tool");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      title: "自由职业者",
      info: {},
      visible: false,
      dataList: [],
      companyName: null,
      freelanceId: null,
      form: {},
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      }
    };
  },
  created: function created() {
    // 获取参数
    var id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.title = this.$route.query.title;
    this.getTaxationList();
    this.getData(id);
  },
  methods: {
    getTaxationList: function getTaxationList() {
      var _this = this;

      (0, _api.taxationList)().then(function (res) {
        _this.dataList = res.data;
      });
    },
    handleOk: function handleOk() {
      var _this2 = this;

      if (!this.form.gsmc) {
        this.$message.error("请选择用工单位!");
      } else {
        (0, _api.freelanceCustomers)({
          freelanceId: this.freelanceId,
          customerId: this.form.gsmc
        }).then(function (res) {
          _this2.visible = false;

          _this2.$message.success("更换成功！");

          _this2.$router.go(-1);
        });
      }
    },
    handleCancel: function handleCancel() {
      this.visible = false;
    },
    showModal: function showModal() {
      this.visible = true;
      this.form = {};
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    getData: function getData(id) {
      var _this3 = this;

      (0, _api.detailReq)(id).then(function (res) {
        var data = (0, _tool.convertPic)(res.data, "idcardFront", "idcardBack", "facePic", "caPic", "sstAuth");
        _this3.companyName = res.data.customerName;
        _this3.freelanceId = res.data.id;
        _this3.info = data;
      });
    }
  }
};
exports.default = _default;