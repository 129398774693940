var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-page" },
    [
      _c(
        "div",
        { staticClass: "nav-box" },
        [
          _c("a-icon", { attrs: { type: "left" }, on: { click: _vm.goBack } }),
          _vm._v("费用发放列表 ")
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticClass: "mb24 mt24",
          attrs: { type: "primary" },
          on: { click: _vm.exportExcel }
        },
        [_vm._v(" 导出 ")]
      ),
      _c("div", { staticClass: "mb24" }, [
        _vm._v(
          " 合计：" +
            _vm._s(
              (_vm.data && _vm.data.length > 0 && _vm.data[0].amountCount) || 0
            ) +
            " "
        )
      ]),
      _c("a-table", {
        attrs: { columns: _vm.columns, "data-source": _vm.data },
        scopedSlots: _vm._u([
          {
            key: "payStatus",
            fn: function(text) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    ["", "待支付", "已支付", "已取消", "支付中", "支付失败"][
                      text
                    ]
                  )
                )
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }