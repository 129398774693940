"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createReq = createReq;
exports.updateReq = updateReq;
exports.deleteReq = deleteReq;
exports.deleteReqs = deleteReqs;
exports.detailReq = detailReq;
exports.listAll = listAll;
exports.retrieveReq = retrieveReq;
exports.contractAgain = contractAgain;
exports.taxationList = taxationList;
exports.freelanceCustomers = freelanceCustomers;
exports.creatCode = creatCode;
exports.getCode = getCode;
exports.listTaskAll = listTaskAll;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 * @Description: 自由职业者
 * @Author: june
 */
// 新增
function createReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelances/saveFreelance",
    method: "post",
    data: data
  });
} // 修改


function updateReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelances",
    method: "put",
    data: data
  });
} // 单个删除


function deleteReq(params) {
  return (0, _request.default)({
    url: "/youhuo/api//v1/freelances/deleteFreelanceById/".concat(params),
    method: "delete"
  });
} // 批量删除


function deleteReqs(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelances",
    method: "delete",
    data: data
  });
} // 根据id查找


function detailReq(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelances/".concat(data),
    method: "get"
  });
} // 查找所有


function listAll(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelances/listAll",
    method: "get",
    params: params
  });
} // 分页查询


function retrieveReq(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelances",
    method: "get",
    params: params
  });
} // 重新发起合同


function contractAgain(id) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelanceVerifys/contractAgain/".concat(id),
    method: "post"
  });
} // 税务端查询上游客户


function taxationList(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customers/taxationList",
    method: "get",
    params: params
  });
} // 新增自由职业者和上游客户的关联关系


function freelanceCustomers(data) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/freelanceCustomers",
    method: "post",
    data: data
  });
} // 生成二维码


function creatCode(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/miniProgram/generateQRCode",
    method: "get",
    params: params
  });
} // 获取二维码


function getCode(params) {
  return (0, _request.default)({
    url: "/youhuo/api/v1/miniProgram/getQRCodeVO",
    method: "get",
    params: params
  });
} // 查找所有


function listTaskAll() {
  return (0, _request.default)({
    url: "/youhuo/api/v1/customerTasks/listTaskAllByToken",
    method: "get"
  });
}