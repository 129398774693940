var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb24 uplaodBox" },
    [
      _c(
        "a-row",
        { staticClass: "bbE8E8E8 h60 lh60" },
        [
          _c(
            "a-col",
            { staticClass: "exportTitle", attrs: { span: 24 } },
            [
              _c("a-icon", {
                attrs: { type: "left" },
                on: { click: _vm.goback }
              }),
              _vm._v("批量导入 ")
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "pl24 pr24 pt24" }, [
        _c(
          "div",
          { staticClass: "tipBox pl24 pr24" },
          [
            _c("a-row", { staticClass: "f16" }, [_vm._v("温馨提示")]),
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: 12 } },
                  [
                    _c("span", { staticClass: "color9 pr24" }, [
                      _vm._v("推荐下载标准模板，填写信息后再上传")
                    ]),
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.download }
                      },
                      [_vm._v("模板下载")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "p24 ant-upload-select-text-box" },
        [
          _c(
            "a-upload",
            {
              ref: "upload",
              attrs: {
                "file-list": _vm.fileList,
                remove: _vm.handleRemove,
                "before-upload": _vm.beforeUpload
              }
            },
            [
              _c("a-icon", { attrs: { type: "upload" } }),
              _c("div", { staticClass: "ant-modal-body" }, [
                _vm._v(" 将文件拖到此处，或 "),
                _c("em", [_vm._v("点击上传")]),
                _c(
                  "div",
                  {
                    staticClass: "pt10",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.lookRole($event)
                      }
                    }
                  },
                  [
                    _vm._v(" ( "),
                    _c("em", [_vm._v("查看上传要求")]),
                    _vm._v(") ")
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p24" },
        [
          _c("a-button", { staticClass: "mr12", on: { click: _vm.reset } }, [
            _vm._v("重置")
          ]),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.fileList.length === 0,
                loading: _vm.uploading
              },
              on: { click: _vm.handleUpload }
            },
            [_vm._v("确定导入")]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "文件上传要求" },
          on: { ok: _vm.handleClose },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c("div", [_c("em", [_vm._v("重要提示")])]),
          _c("div", [_vm._v("1.支持WPS、excel2003以上版本文件")]),
          _c("div", [_vm._v("2.请将要上传的内容放在模板的员工数据中")]),
          _c("div", [_vm._v("3.请不要加密模板文件，将导致错误")]),
          _c("div", [_vm._v("4.请不要上传带宏的文件，将导致错误")]),
          _c("div", [_vm._v("5.单次最多导入2000人，超出请分批上传")])
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "未成功名单", width: "800px" },
          on: { ok: _vm.handleFailClose },
          model: {
            value: _vm.dialogFailVisible,
            callback: function($$v) {
              _vm.dialogFailVisible = $$v
            },
            expression: "dialogFailVisible"
          }
        },
        [
          _c("a-table", {
            key: "id",
            attrs: { columns: _vm.columns, "data-source": _vm.Faildata },
            on: { change: _vm.handleTableChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }