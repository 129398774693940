"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _sidebar = _interopRequireDefault(require("./components/sidebar"));

var _navbar = _interopRequireDefault(require("./components/navbar"));

var _appmain = _interopRequireDefault(require("./components/appmain"));

var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Layout",
  components: {
    sidebar: _sidebar.default,
    navbar: _navbar.default,
    AppMain: _appmain.default
  },
  mixins: [_ResizeHandler.default],
  data: function data() {
    return {
      collapsed: false
    };
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    fixed_header: function fixed_header() {
      return this.$store.state.settings.fixed_header;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  }
};
exports.default = _default;