"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.slice");

require("core-js/modules/es.array.splice");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _xqjrModuleAuth = require("xqjr-module-auth");

var _api = require("../../task/customerTask/api");

var _axios = _interopRequireDefault(require("axios"));

var _antDesignVue = require("ant-design-vue");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { listAll as listCompanyAll } from "../company/api";
var columns = [{
  title: "手机号",
  dataIndex: "phone"
}, {
  title: "姓名",
  dataIndex: "name"
}, {
  title: "原因",
  dataIndex: "reason"
}];
var _default = {
  data: function data() {
    return {
      id: "",
      columns: columns,
      data: {},
      fileList: [],
      uploading: false,
      selectedRowKeys: [],
      selectTask: "",
      headers: {},
      Faildata: [],
      file: "",
      list: [],
      CompanyList: [],
      selectCustomer: "",
      selectCompany: "",
      dialogVisible: false,
      dialogFailVisible: false,
      companyName: "",
      customerName: ""
    };
  },
  created: function created() {
    var _this = this;

    this.headers = {
      token: (0, _xqjrModuleAuth.getToken)(),
      "Content-Type": "multipart/form-data"
    }; // this.id = this.$store.state.project.projectId;

    (0, _api.listTaskAll)().then(function (res) {
      console.log(res);
      _this.list = res.data;
      _this.selectTask = res.data && res.data.length > 0 && res.data[0].id;
      _this.companyName = res.data && res.data.length > 0 && res.data[0].companyName;
      _this.customerName = res.data && res.data.length > 0 && res.data[0].customerName;
    }); // listCompanyAll().then(res => {
    //   console.log(res);
    //   this.CompanyList = res.data;
    //   this.selectCompany = res.data && res.data.length > 0 && res.data[0].id;
    // });
  },
  methods: {
    taskChange: function taskChange(id) {
      var obj = this.list.filter(function (item) {
        return item.id === id;
      })[0];
      this.companyName = obj.companyName;
      this.customerName = obj.customerName;
    },
    handleRemove: function handleRemove(file) {
      var index = this.fileList.indexOf(file);
      var newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload: function beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    handleUpload: function handleUpload() {
      var _this2 = this;

      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
        });
        this.uploading = true;
        (0, _axios.default)({
          url: "/youhuo/api/v1/freelances/import?taskId=".concat(this.selectTask, "&force=false"),
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this2.handleSuccess(res.data);

          _this2.uploading = false;
        });
      } else {
        this.$message.warning("没有文件");
      }
    },
    onSelectChange: function onSelectChange() {},
    handleTableChange: function handleTableChange() {},
    handleFailClose: function handleFailClose() {
      this.dialogFailVisible = false;
      this.reset();
    },
    handleSuccess: function handleSuccess(res) {
      var _this3 = this;

      var that = this;

      if (res.success && res.data !== null) {
        var numAll = res.data.importFailList.length + res.data.importSucList.length;
        var numFail = res.data.importFailList.length;
        var numSuc = res.data.importSucList.length;

        _antDesignVue.Modal.confirm({
          okText: "确定",
          cancelText: "查看未成功名单",
          title: "\u5171\u5BFC\u5165".concat(numAll, "\u4EBA\uFF0C").concat(numSuc, "\u4EBA\u6210\u529F\uFF0C").concat(numFail, "\u4EBA\u672A\u6210\u529F"),
          onOk: function onOk() {
            that.goback();
          },
          onCancel: function onCancel() {
            that.Faildata = res.data.importFailList;
            that.dialogFailVisible = true;
          }
        });
      } else if (res.data === null) {
        _antDesignVue.Modal.confirm({
          okText: "确定",
          cancelText: "取消",
          title: res.msg
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: res.msg || "上传失败"
        });
        setTimeout(function () {
          _this3.reset();
        }, 2000);
      }
    },
    goback: function goback() {
      var path = "/basic/freelance";
      this.$router.push({
        path: path
      });
    },
    download: function download() {
      window.open( // "https://res.lanxiang.co/prod/6cfb24cf-99c0-41ea-8de2-95cfda99371b/2e04179c-3c3c-446a-a52b-e3d33ac94ccb.xlsx"
      "/youhuo/api/v1/excel/template/download?templateName=freelanceImportTemplate.xlsx");
    },
    reset: function reset() {
      this.fileList = [];
    },
    lookRole: function lookRole() {
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;