"use strict";

var _interopRequireWildcard = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("/data/jenkins/workspace/dev-e-youhuo/node_modules/core-js/modules/es.array.iterator.js");

require("/data/jenkins/workspace/dev-e-youhuo/node_modules/core-js/modules/es.promise.js");

require("/data/jenkins/workspace/dev-e-youhuo/node_modules/core-js/modules/es.object.assign.js");

require("/data/jenkins/workspace/dev-e-youhuo/node_modules/core-js/modules/es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _moment = _interopRequireDefault(require("moment"));

require("moment/locale/zh-cn");

var _antDesignVue = _interopRequireWildcard(require("ant-design-vue"));

require("ant-design-vue/dist/antd.less");

var _kFormDesignCallcter = _interopRequireDefault(require("k-form-design-callcter"));

require("k-form-design-callcter/lib/k-form-design-mini.css");

require("xqjr-plugin-common/AutoGenerate/CustomFormDesign");

require("@/styles/index.scss");

require("xqjr-plugin-form/styles/index.scss");

var _xqjrPluginCommon = require("xqjr-plugin-common");

var _vuePerfectScrollbar = _interopRequireDefault(require("vue-perfect-scrollbar"));

var _App = _interopRequireDefault(require("./App.vue"));

var _router = _interopRequireDefault(require("./router"));

var _store = _interopRequireDefault(require("./store"));

var _xqjrModuleAuth = require("xqjr-module-auth");

var _request = _interopRequireDefault(require("@/utils/request.js"));

_moment.default.locale("zh-cn");

_vue.default.use(_antDesignVue.default);

_vue.default.use(_kFormDesignCallcter.default);

var XqIcon = _antDesignVue.Icon.createFromIconfontCN({
  scriptUrl: "font_1637251_9pxt7jv5b6youhuo.js"
});

_vue.default.component("xq-icon", XqIcon);

_vue.default.component("xq-tabs", _xqjrPluginCommon.XqTabs);

_vue.default.component("xq-scrollbar", _vuePerfectScrollbar.default);

_store.default.registerModule("auth", _xqjrModuleAuth.store);

_store.default.registerModule("common", _xqjrPluginCommon.store);

_vue.default.prototype.$request = _request.default;
_vue.default.prototype.moment = _moment.default;
_vue.default.config.productionTip = false;
new _vue.default({
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount("#app");