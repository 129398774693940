"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2"));

var _antDesignVue = require("ant-design-vue");

var _item = _interopRequireDefault(require("./item"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SubMenu",
  components: {
    Item: _item.default
  },
  props: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _antDesignVue.Menu.SubMenu.props), {}, {
    item: {
      type: Object,
      required: true
    }
  })
};
exports.default = _default;