"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _YHHeader = _interopRequireDefault(require("./YHHeader"));

var _YHFooter = _interopRequireDefault(require("./YHFooter"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  components: {
    YHHeader: _YHHeader.default,
    YHFooter: _YHFooter.default
  },
  props: {},
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};
exports.default = _default;