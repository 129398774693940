"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.slice");

require("core-js/modules/es.array.splice");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2"));

var _xqjrModuleAuth = require("xqjr-module-auth");

var _api = require("../../task/customerTask/api");

var _api2 = require("./api");

var _axios = _interopRequireDefault(require("axios"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var columns = [{
  title: "手机号",
  dataIndex: "phone",
  key: "phone"
}, {
  title: "姓名",
  dataIndex: "userName",
  key: "userName"
}, {
  title: "金额",
  dataIndex: "amount",
  key: "amount"
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark"
}];
var _default = {
  data: function data() {
    return {
      show: "show",
      id: "",
      columns: columns,
      data: [],
      failList: [],
      successList: [],
      fileList: [],
      uploading: false,
      selectedRowKeys: [],
      headers: {},
      Faildata: [],
      fileURL: "",
      file: "",
      list: [],
      selectCustomer: "",
      dialogVisible: false,
      dialogFailVisible: false
    };
  },
  created: function created() {
    var _this = this;

    this.headers = {
      token: (0, _xqjrModuleAuth.getToken)(),
      "Content-Type": "multipart/form-data"
    }; // this.id = this.$store.state.project.projectId;

    (0, _api.listTaskAll)().then(function (res) {
      console.log(res);
      _this.list = res.data;
      _this.selectCustomer = res.data && res.data.length > 0 && res.data[0].id;
    });
  },
  methods: {
    handleRemove: function handleRemove(file) {
      var index = this.fileList.indexOf(file);
      var newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload: function beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    handleUpload: function handleUpload() {
      var _this2 = this;

      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
        });
        this.uploading = true;
        (0, _axios.default)({
          url: "/youhuo/api/v1/freelancePayRecords/import?taskId=".concat(this.selectCustomer, "&force=false"),
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this2.handleSuccess(res.data);

          _this2.uploading = false;
        });
        var fileData = new FormData();
        fileList.forEach(function (file) {
          fileData.append("files", file);
        });
        (0, _api2.uploadFile)(fileData).then(function (res) {
          _this2.fileURL = res.data[0];
          _this2.show = "hide";
        });
      } else {
        this.$message.warning("没有文件");
      }
    },
    onSelectChange: function onSelectChange() {},
    handleTableChange: function handleTableChange() {},
    handleFailClose: function handleFailClose() {
      this.dialogFailVisible = false;
      this.reset();
    },
    handleSuccess: function handleSuccess(res) {
      var _this3 = this;

      var that = this;

      if (res.success && res.data !== null) {
        this.data = res.data;
        this.successList = res.data.freelancePayRecordSuccessList;
        this.failList = res.data.freelancePayRecordFailList;
      } else if (res.data === null) {
        this.$confirm({
          okText: "确定",
          cancelText: "取消",
          title: res.msg
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: res.msg || "上传失败"
        });
        setTimeout(function () {
          _this3.reset();
        }, 2000);
      }
    },
    nextProcess: function nextProcess() {
      var _this4 = this;

      if (this.successList.length === 0) {
        this.$notification["error"]({
          message: "error",
          description: "没有发放人员，请重新操作"
        });
        return;
      }

      (0, _api2.freelancePayRecordSave)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.data), {}, {
        url: this.fileURL
      })).then(function (res) {
        // this.$notification["success"]({
        //   message: "Success",
        //   description: "操作成功"
        // });
        _this4.$router.push({
          path: "/provide/customerOrder/order?orderNo=" + res.data
        });
      }).catch(function (err) {
        _this4.$notification["error"]({
          message: "error",
          description: err.msg || "操作失败"
        });
      });
    },
    goback: function goback() {
      var path = "/provide/customerOrder";
      this.$router.push({
        path: path
      });
    },
    download: function download() {
      window.open( // "https://res.lanxiang.co/prod/ca7b7cfa-fbcb-4581-9eea-faf61e2b8413/93332bd3-4ea9-4f2c-80c7-3aabc909b3f2.xlsx"
      "/youhuo/api/v1/excel/template/download?templateName=freelancePayRecordImportTemplate.xlsx");
    },
    reset: function reset() {
      this.fileList = [];
    },
    goImport: function goImport() {
      this.show = "show";
    },
    lookRole: function lookRole() {
      this.dialogVisible = true;
    },
    goMoney: function goMoney() {
      this.$router.push({
        path: "/cost/customerRecharge"
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;