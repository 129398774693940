import { render, staticRenderFns } from "./YHCard2.vue?vue&type=template&id=22ad43bd&scoped=true&"
import script from "./YHCard2.vue?vue&type=script&lang=js&"
export * from "./YHCard2.vue?vue&type=script&lang=js&"
import style0 from "./YHCard2.vue?vue&type=style&index=0&id=22ad43bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ad43bd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22ad43bd')) {
      api.createRecord('22ad43bd', component.options)
    } else {
      api.reload('22ad43bd', component.options)
    }
    module.hot.accept("./YHCard2.vue?vue&type=template&id=22ad43bd&scoped=true&", function () {
      api.rerender('22ad43bd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/YHCard2.vue"
export default component.exports